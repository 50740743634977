import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import PublicLayout from "../layout/PublicLayout";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <PublicLayout children={<HomePage />} />,
  },
]);
