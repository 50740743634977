import { Container } from "@mui/material";
import Header from "../components/Header";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Footer from "../components/Footer";

const PublicLayout = ({ children }) => {

  return (
    <Container maxWidth="lg" sx={{ position: "relative" }}>
      <Header />
      {children}
      <ScrollToTopButton />
      <Footer />
    </Container>
  );
};

export default PublicLayout;
