import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Avatar,
  TextField,
  useTheme,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "material-ui-snackbar-provider";
import Martin from "../assets/img/martin.jpg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: { name: "", email: "", phone: "", message: "" },
  });
  const onSubmit = async (data) => {
    try {
      data.to_name = "Hynx Ltd team";
      const response = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        data,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      );
      snackbar.showMessage(
        "Our representative will contact you within 24 hours",
        "OK",
        () => {}
      );
    } catch (e) {
      snackbar.showMessage(e.message, "OK", () => {});
    }
  };
  // eslint-disable-next-line
  const [countryCode, setCountryCode] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://geolocation-db.com/json/");
        setCountryCode(res.data.country_code);
      } catch (e) {}
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        bgcolor: "rgba(0, 0, 0, 0.07)",
        display: "flex",
        flexDirection: "column",
      }}
      id="contact-id"
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h4" fontWeight={700}>
              Contact Us
            </Typography>
            <Typography variant="body1">
              Our representative will contact you within 24 hours, we will sign
              an NDA to keep your ideas confidential and discuss the main
              requirements of the project. After that, our analysts and
              developers will carefully study them, and together we will come up
              with the next move.
            </Typography>
            <Stack direction="row" spacing={2}>
              <Typography variant="body1">
                Simply fill out the form or email us at
              </Typography>
              <Typography
                variant="body1"
                component="a"
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                sx={{
                  color: pink[500],
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography variant="body1">Or call us</Typography>
              <Typography
                variant="body1"
                component="a"
                href="tel:+19282514577"
                sx={{
                  color: pink[500],
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {process.env.REACT_APP_SUPPORT_PHONE}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={4} alignItems="center">
              <Avatar src={Martin} sx={{ width: 150, height: 150 }} />
              <Stack direction="column" spacing={0}>
                <Typography variant="h6">Martin Surgenor</Typography>
                <Typography variant="body1">
                  CEO of {process.env.REACT_APP_COMPANY_DOMAIN_NAME}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  component="a"
                  href={process.env.REACT_APP_LINKEDIN_URL}
                  sx={{ color: theme.palette.primary.main }}
                >
                  <LinkedInIcon />
                  <Typography variant="body1">Linkedin</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("from_name", { required: "Name is required" })}
              variant="standard"
              type="text"
              label="Name"
              error={!!errors.name}
              helperText={errors?.name?.message || " "}
            />
            <TextField
              label="Email"
              helperText={errors?.email?.message || " "}
              error={!!errors.email}
              variant="standard"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            <Controller
              name="phone"
              control={control}
              rules={{ validate: matchIsValidTel }}
              render={({ field, fieldState }) => (
                <MuiTelInput
                  {...field}
                  label="phone"
                  defaultCountry="US"
                  variant="standard"
                  helperText={
                    fieldState.invalid ? "Phone number is invalid" : " "
                  }
                  error={fieldState.invalid}
                />
              )}
            />
            <TextField
              {...register("message", { required: "Message is required" })}
              variant="standard"
              name="message"
              type="text"
              label="Message and questions"
              multiline
              rows={4}
              error={!!errors.message}
              helperText={errors?.message?.message || " "}
            />
            <LoadingButton
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              loading={isSubmitting}
              sx={{ mt: 1 }}
            >
              Send message
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
export default ContactUs;
