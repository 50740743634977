import { Box } from "@mui/material";
import AboutUs from "../components/AboutUs";
import OurValues from "../components/OurValues";
import WhyChooseUs from "../components/WhyChooseUs";
import ContactUs from "../components/ContactUs";
import Hero from "../components/Hero";

const HomePage = () => {
  return (
    <Box>
      <Hero />
      <AboutUs />
      <OurValues />
      <WhyChooseUs />
      <ContactUs />
    </Box>
  );
};

export default HomePage;
