import * as React from "react";
import { Box, Typography, Card, CardMedia, CardContent } from "@mui/material";
import { pink } from "@mui/material/colors";
import ServiceMobile from "../assets/img/service-mobile.jpg";
import peopleImg from "../assets/img/people.jpg";
import truthImg from "../assets/img/truth.jpg";

const items = [
  {
    title: "PEOPLE",
    image: peopleImg,
    description:
      "Collaboration is the foundation of our success, we respect what makes us diffrent, and focus on the needs and well-being, of our team members, suppliers and customers.",
  },
  {
    title: "CREATIVITY",
    image: ServiceMobile,
    description:
      "Problems and adversity challenge us to develop new solutions, think beyond conventional boundaries, and experiment with new ideas. We take risks and acknowledge failure, as a part of growth.",
  },
  {
    title: "INTEGRITY",
    image: truthImg,
    description:
      "We seek truth openly even when it is challenging or uncomfortable, this allows us to make informed decisions, and build trust in our selves and our systems.",
  },
];

function OurValues() {
  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        bgcolor: "rgba(0, 0, 0, 0.2)",
        display: "flex",
        flexDirection: "column",
      }}
      id="our-values-id"
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Our values
      </Typography>
      <Box
        sx={{ p: { xs: 2, md: 4 } }}
        gap={4}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="center"
      >
        {items.map((item, index) => (
          <Card sx={{ maxWidth: 350 }} key={item.title + index}>
            <CardMedia
              sx={{ height: 250, filter: "grayscale(1)", m: 1 }}
              image={item.image}
              title="green iguana"
              key={item + index}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontWeight: 700,
                  color: pink[500],
                }}
              >
                {item.title}
              </Typography>
              <Typography variant="body1" color="text.primary">
                {item.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
export default OurValues;
