import * as React from "react";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo.svg";

const menuItems = [
  { label: "Our values", to: "our-values-id" },
  { label: "Why choose us?", to: "why-us-id" },
  { label: "Contact", to: "contact-id" },
];

function Header() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isHeaderVisible, setHeaderVisible] = React.useState(true);
  const [lastScrollTop, setLastScrollTop] = React.useState(0);

  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const isScrollingDown =
        currentScrollTop > 100 && currentScrollTop > lastScrollTop;

      setHeaderVisible(isScrollingDown ? false : true);
      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      minHeight="100vh"
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={item.label + index} disablePadding>
            <ListItemButton onClick={() => scrollTo(item.to)}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: theme.zIndex.appBar,
        transition: "transform 0.2s ease-in-out",
        transform: isHeaderVisible ? "translateY(0)" : "translateY(-100%)",
        boxShadow: theme.shadows[25],
      }}
    >
      <Drawer anchor="top" open={openDrawer} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX={{ xs: 2, md: 4 }}
          paddingY={{ xs: 2, md: 3 }}
          bgcolor="rgba(0, 0, 0, 0.8)"
        >
          <Box width={100} height={37} component={Link} href="/">
            <img src={Logo} alt="Hynx Ltd logo" width="100%" />
          </Box>
          <IconButton
            sx={{
              color: "white",
              width: 50,
              height: 50,
              display: { xs: "block", md: "none" },
            }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon sx={{ width: 35, height: 35 }} />
          </IconButton>
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {menuItems.map((item, index) => (
              <Link
                variant="h6"
                sx={{ color: "white", cursor: "pointer" }}
                component={RouterLink}
                key={JSON.stringify(item.label + index)}
                // to={item.to}
                onClick={() => scrollTo(item.to)}
                underline="hover"
              >
                {item.label}
              </Link>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
export default Header;
