import { Box, Typography } from "@mui/material";

const Hero = () => {
  return (
    <Box
      sx={{
        paddingTop: 10,
        backgroundPosition: "top center",
        backgroundSize: "cover",
      }}
      className="header-bg"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: 16,
        }}
      >
        <Typography sx={{ color: "white", mb: 2 }} variant="h5">
          Digital Services
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "center", md: "baseline" },
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1, md: 2 },
          }}
        >
          <Typography variant="h3" sx={{ color: "white" }}>
            We build
          </Typography>
          <Typography variant="h3" color="primary" sx={{ fontWeight: 700 }}>
            Experiences
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
