import * as React from "react";
import { Box, Typography, Grid, Stack } from "@mui/material";
import img1 from "../assets/img/why_choose_us1.jpg";
import img2 from "../assets/img/why_choose_us2.jpg";
import img3 from "../assets/img/why_choose_us3.jpg";
import img4 from "../assets/img/why_choose_us4.jpg";

const items = [
  {
    title: "Industry-leading Expertise",
    image: img1,
    description:
      "Our team of skilled developers, designers, and project managers are experienced in crafting high-performance, scalable, and secure SaaS solutions that drive growth and efficiency for businesses across diverse industries.",
  },
  {
    title: "Customer-centric Approach",
    image: img2,
    description:
      "We take the time to understand your business, audience, and objectives, ensuring we deliver tailored solutions that not only solve problems but also exceed expectations.",
  },
  {
    title: "Agile Methodologies",
    image: img3,
    description:
      "We employ agile development practices to guarantee flexibility, rapid iteration, and timely delivery, keeping you informed and involved throughout the entire development process.",
  },
  {
    title: "End-to-End Support",
    image: img4,
    description:
      "From ideation and planning to development, deployment, and ongoing maintenance, our comprehensive support ensures your SaaS application remains up-to-date, secure, and effective.",
  },
];

function WhyChooseUs() {
  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        // bgcolor: "rgba(0, 0, 0, 0.01)",
        display: "flex",
        flexDirection: "column",
      }}
      id="why-us-id"
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Why choose us?
      </Typography>
      <Stack sx={{ p: { xs: 2, md: 4 } }} spacing={12}>
        {items.map((item, index) => (
          <Box px={6} key={`card-${index}`}>
            <Grid
              container
              direction={index % 2 ? "row" : "row-reverse"}
              spacing={6}
            >
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    backgroundImage: `url("${item.image}")`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    width: "100%",
                    maxWidth: 400,
                    height: 200,
                    borderRadius: 1,
                    filter: "grayscale(1)",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} display="flex" alignItems="center">
                <Box>
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography variant="body1">{item.description}</Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
export default WhyChooseUs;
