import * as React from "react";
import { Link, Stack, Typography } from "@mui/material";
function Footer() {
  return (
    <Stack
      sx={{
        p: { xs: 2, md: 4 },
        bgcolor: "rgba(0, 0, 0, 0.7)",
        color: "white",
      }}
      spacing={1}
    >
      <Link
        variant="h6"
        sx={{ fontWeight: "bold" }}
        component="a"
        href="https://find-and-update.company-information.service.gov.uk/company/10514108"
        target="blank"
      >
        Hynx Ltd, 10514108
      </Link>
      <Typography variant="body1">
        128 City Road, London, United Kingdom, EC1V 2NX
      </Typography>
      <Typography variant="caption">
        © 2023-{new Date().getFullYear()} hynx.net. All rights reserved.
      </Typography>
    </Stack>
  );
}
export default Footer;
